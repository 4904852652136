import { createGlobalStyle } from 'styled-components';

export const CSSVars = {
  primaryFont: `var(--primary-font)`,
  secondaryFont: `var(--secondary-font)`,

  blue50: `var(--blue-50)`,
  blue100: `var(--blue-100)`,
  blue200: `var(--blue-200)`,
  blue300: `var(--blue-300)`,
  blue400: `var(--blue-400)`,
  blue500: `var(--blue-500)`,
  blue600: `var(--blue-600)`,
  blue700: `var(--blue-700)`,
  blue800: `var(--blue-800)`,
  blue900: `var(--blue-900)`,
  blue950: `var(--blue-950)`,

  pink50: `var(--pink-50)`,
  pink100: `var(--pink-100)`,
  pink200: `var(--pink-200)`,
  pink300: `var(--pink-300)`,
  pink400: `var(--pink-400)`,
  pink500: `var(--pink-500)`,
  pink600: `var(--pink-600)`,
  pink700: `var(--pink-700)`,
  pink800: `var(--pink-800)`,
  pink900: `var(--pink-900)`,
  pink950: `var(--pink-950)`,

  gray50: `var(--gray-50)`,
  gray100: `var(--gray-100)`,
  gray200: `var(--gray-200)`,
  gray300: `var(--gray-300)`,
  gray400: `var(--gray-400)`,
  gray500: `var(--gray-500)`,
  gray600: `var(--gray-600)`,
  gray700: `var(--gray-700)`,
  gray800: `var(--gray-800)`,
  gray900: `var(--gray-900)`,
  gray950: `var(--gray-950)`,

  black: `var(--black)`,
  white: `var(--white)`,
  gray: `var(--gray)`,
  pink: `var(--pink)`,
  blue: `var(--blue)`,

  fontSizeXS: 'var(--font-size-xs)',
  fontSizeSM: 'var(--font-size-sm)',
  fontSizeMD: 'var(--font-size-md)',
  fontSizeLG: 'var(--font-size-lg)',
  fontSizeXL: 'var(--font-size-xl)',
  fontSize2XL: 'var(--font-size-2xl)',
  fontSize3XL: 'var(--font-size-3xl)',
  fontSize4XL: 'var(--font-size-4xl)',
  fontSize5XL: 'var(--font-size-5xl)',
  fontSize6XL: 'var(--font-size-6xl)',

  breakpointSM: '480px',
  breakpointMD: '768px',
  breakpointLG: '992px',
  breakpointXL: '1280px',
  breakpoint2XL: '1536px',
};

export const GlobalStyles = createGlobalStyle`

  :root{
    --primary-font: 'Poppins', sans-serif;
    --secondary-font: 'Open Sans', sans-serif;

    --blue-50: #ecf1ff;
    --blue-100: #dde5ff;
    --blue-200: #c2cfff;
    --blue-300: #9caeff;
    --blue-400: #7581ff;
    --blue-500: #4c4fff; // Main Color
    --blue-600: #4236f5;
    --blue-700: #382ad8;
    --blue-800: #2e25ae;
    --blue-900: #292689;
    --blue-950: #1a1650;

    --pink-50: #fef2f3;
    --pink-100: #ffe1e2;
    --pink-200: #ffc8cb;
    --pink-300: #ff979c; // Main Color
    --pink-400: #fd6c73;
    --pink-500: #f53e47;
    --pink-600: #e22029;
    --pink-700: #be171f;
    --pink-800: #9d171d;
    --pink-900: #821a1f;
    --pink-950: #47080b;
    
    --gray-50: #f5f5f5; // Main Color
    --gray-100: #e7e7e7;
    --gray-200: #d1d1d1;
    --gray-300: #b0b0b0;
    --gray-400: #888888;
    --gray-500: #6d6d6d;
    --gray-600: #5d5d5d;
    --gray-700: #4f4f4f;
    --gray-800: #454545;
    --gray-900: #3d3d3d;
    --gray-950: #010101; // Main Color
    
    /* Main Color Pallete */
    --black: #010101;
    --white: #fff; 
    --gray: #f5f5f5; 
    --pink: #ff979c; 
    --blue: #4c4fff; 

    --font-size-xs: 1.2rem;
    --font-size-sm: 1.4rem;
    --font-size-md: 1.6rem;
    --font-size-lg: 1.8rem;
    --font-size-xl: 2rem;
    --font-size-2xl: 3.4rem;
    --font-size-3xl: 5rem;
    --font-size-4xl: 7.5rem;
    --font-size-5xl: 9.5rem;
    --font-size-6xl: 17rem;
  }

  /* Reset */

  * {
    box-sizing: border-box;
  }

  /* Reset */
  
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    background: transparent;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  :focus {
    outline: 0;
  }

  ins {
    text-decoration: none;
  }

  del {
    text-decoration: line-through;
  }

  table {
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:focus {
    /* outline: 0; */
  }

  strong, b {
    font-weight: 700;
  }

  html {
    font-size: 62.5%; // 1rem = 10px
    color: ${CSSVars.white};
		background: ${CSSVars.black};
  }

  body {
    font-weight: 300;
    font-family: var(--primary-font);
    font-size: ${CSSVars.fontSizeMD};
    overflow-x: hidden;
  }

  .volume-slider {
    /* for audio player examples */
    background: #fff;
    padding: 5px 40px !important;
    
    input {
      padding: 0px;
      background-color: transparent;
      border: none;
    }
  }

  input {
    outline: none !important;
    padding: 8px 20px 8px 35px;
    font-size: ${CSSVars.fontSizeSM};
    border: 1px solid ${CSSVars.gray300};
    border-radius: 5px;
    color: ${CSSVars.gray600};
    font-weight: 300;
    font-family: ${CSSVars.primaryFont};
    
    &::placeholder{
      color: ${CSSVars.gray400};
    }
  }

  .search-components {
		position: relative;
		max-width: 440px;
		margin: 0 auto;

		.reset {
			position: absolute;
			top: 6px;
			right: 10px;
			color: inherit;
			cursor: pointer;
			color: gray;
		}

		input {
			padding-right: 20px;
			position: relative;
			background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.34938 1.60176C7.21356 -0.533921 3.73735 -0.533921 1.60152 1.60176C-0.53384 3.73791 -0.53384 7.21343 1.60152 9.34958C3.50353 11.251 6.46581 11.4547 8.60025 9.96996C8.64516 10.1825 8.74795 10.3852 8.91324 10.5505L12.0237 13.6608C12.477 14.1131 13.2094 14.1131 13.6604 13.6608C14.1132 13.208 14.1132 12.4756 13.6604 12.0242L10.5499 8.913C10.3856 8.7491 10.1823 8.64586 9.96981 8.60095C11.4556 6.46619 11.2519 3.50457 9.34938 1.60176ZM8.36736 8.36761C6.77278 9.96209 4.17766 9.96209 2.58355 8.36761C0.989897 6.77314 0.989897 4.17866 2.58355 2.58419C4.17766 0.990178 6.77278 0.990178 8.36736 2.58419C9.96194 4.17866 9.96194 6.77314 8.36736 8.36761Z' fill='%23B4B4B4'/%3E%3C/svg%3E%0A");
			background-position: 13px 45%;
			background-repeat: no-repeat;
			width: 100%;
		}
	}
`;